<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerAction>
            <router-link to="other-honorarium-sheet-list" :class="'mr-2 btn btn-success text-light'">{{ $t('elearning_tim.other_honorarium_amount') }} {{ $t('globalTrans.list') }}</router-link>
            <!-- <b-button variant="primary" class="">
                 <i class="fas fa-print"></i> {{  $t('globalTrans.print') }}
            </b-button> -->
          </template>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                  <b-col>
                      <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="16" :office-id="honorariumSheet.office_id">
                          {{ $t('elearning_tim.other_honorarium_sheet') + ' ' + $t('globalTrans.details') }}
                      </list-report-head>
                  </b-col>
              </b-row>
              <b-row>
                <b-col lg="12" sm="12">
                  <b-table-simple class="tg mt-3" hover small caption-top>
                    <tbody>
                      <tr>
                        <th style="width: 15%"  class="text-left" >{{ $t('elearning_iabm.circular_memo_no') }}</th>
                        <th class="text-center" style="width: 3%">:</th>
                        <td style="width: 32%" class="text-left">{{ honorariumSheet.circular_memo_no }}</td>
                        <th style="width: 15%"  class="text-left" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">{{ $t('elearning_config.organization') }}</th>
                        <th style="width: 3%" class="text-center" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">:</th>
                        <td style="width: 32%" class="text-left" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">{{ $i18n.locale === 'bn' ? honorariumSheet.org_bn : honorariumSheet.org }}</td>
                      </tr>
                      <tr>
                        <th style="width: 15%" class="text-left" >{{ $t('elearning_config.fiscal_year') }}</th>
                        <th class="text-center" style="width: 3%">:</th>
                        <td style="width: 32%" class="text-left">{{ $i18n.locale === 'bn' ? honorariumSheet.fiscal_year_bn : honorariumSheet.fiscal_year }}</td>
                        <th style="width: 15%"  class="text-left">{{ $t('elearning_config.office_type') }}</th>
                        <th class="text-center" style="width: 3%">:</th>
                        <td style="width: 32%">{{ $i18n.locale === 'bn' ? honorariumSheet.office_type_bn : honorariumSheet.office_type }}</td>
                      </tr>
                      <tr>
                        <th style="width: 15%"  class="text-left">{{ $t('globalTrans.office') }}</th>
                        <th style="width: 3%" class="text-center">:</th>
                        <td style="width: 32%">{{ $i18n.locale === 'bn' ? honorariumSheet.office_bn : honorariumSheet.office }}</td>
                        <th style="width: 15%"  class="text-left">{{ $t('elearning_config.training_type') }}</th>
                        <th style="width: 3%" class="text-center">:</th>
                        <td style="width: 32%">{{ $i18n.locale === 'bn' ? honorariumSheet.training_type_bn : honorariumSheet.training_type }}</td>
                      </tr>
                      <tr>
                        <th style="width: 15%"  class="text-left">{{ $t('elearning_config.training_category') }}</th>
                        <th style="width: 3%" class="text-center">:</th>
                        <td style="width: 32%">{{ $i18n.locale === 'bn' ? honorariumSheet.training_category_bn : honorariumSheet.training_category }}</td>
                        <th style="width: 15%"  class="text-left">{{ $t('elearning_config.training_title') }}</th>
                        <th style="width: 3%" class="text-center">:</th>
                        <td style="width: 32%">{{ $i18n.locale === 'bn' ? honorariumSheet.training_title_bn : honorariumSheet.training_title }}</td>
                      </tr>
                    </tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
              <b-overlay :show="load">
                <b-row id="form">
                  <div class="col-md-12">
                    <fieldset class="p-2 w-100">
                      <legend class="px-2 w-50 shadow-sm">{{$t('globalTrans.details')}}</legend>
                      <div class="table-responsive">
                        <b-table-simple class="table" style="width:100%" bordered>
                          <b-thead class="thead">
                            <b-tr>
                              <th class="text-center" style="width:5%">{{$t('globalTrans.sl_no')}}</th>
                              <th style="width:12%">{{$t('globalTrans.name')}}</th>
                              <th style="width:12%">{{$t('globalTrans.designation')}}</th>
                              <!-- <th style="width:12%">{{$t('globalTrans.date')}}</th> -->
                              <th class="text-center" v-for="(date, index1) in dateList" :key="index1">
                                {{ date | dateFormat }}
                              </th>
                            </b-tr>
                          </b-thead>
                          <b-tr v-for="(data, index) in trainees" :key="index">
                            <b-td class="text-center" >
                              {{ $n(index + 1) }}
                            </b-td>
                            <b-td>
                              {{ $i18n.locale === 'bn' ? data.personal.name_bn : data.personal.name }}
                            </b-td>
                            <b-td>
                              {{ ($i18n.locale==='bn') ? data.personal.designation_bn : data.personal.designation }}
                            </b-td>
                            <!-- <b-td class="text-center" v-for="(date, index1) in trainees.dateList" :key="index1">
                                {{ date | dateFormat }}
                              </b-td> -->
                            <b-td class="text-center" v-for="(date, index1) in dateList" :key="index1">
                              <a href="javascript:" class="btn_table_action table_action_view" title="View Details" v-b-modal.modal-5 @click="details(data, date)">
                                  <i class="fas fa-eye"></i>
                              </a>
                            </b-td>
                            <!-- <b-td>
                              <a href="javascript:" class="btn_table_action table_action_view" title="View Details" v-b-modal.modal-5 @click="details(data)">
                                  <i class="fas fa-eye"></i>
                              </a>
                            </b-td> -->
                          </b-tr>
                        </b-table-simple>
                      </div>
                    </fieldset>
                  </div>
                </b-row>
              </b-overlay>
            </b-overlay>
          </template>
        </iq-card>
        <!-- <pre>{{trainees}}</pre> -->
      </b-col>
    </b-row>
    <b-modal id="modal-5" size="lg" :title="$t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <b-button variant="primary" @click="pdfExportSheet()" class="ml-4 btn-success water-test-pdf-button">
                      {{  $t('globalTrans.export_pdf') }}
                    </b-button>
      <Sheet :item="item" :date="date" :key="item" ref="details"></Sheet>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import flatpickr from 'flatpickr'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import { billPaymentHonorarumDetails } from '../../api/routes'
import Sheet from './Sheet'

export default {
    components: {
        ListReportHead, Sheet
    },
    created () {
        if (this.$route.query.circilarMemoNo) {
            this.getCircularMemoNo(this.$route.query.circilarMemoNo)
        }
        // this.getMaterils(this.honorariumSheet.training_title_id)
    },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('elearning_config.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        name: '',
        email: '',
        mobile: '',
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        room_type_id: 0,
        room_rent_id: 0,
        floor_id: 0,
        room_id: 0
      },
      load: false,
      officeTypeList: [],
      honorariumSheet: [],
      materials: [],
      items: [],
      training: [],
      trainees: [],
      trainers: [],
      officeList: [],
      dateList: [],
      roomlist: [],
      floorlist: [],
      roomRentList: [],
      trainingCategoryList: [],
      dateErrorMsg: '',
      item: '',
      date: '',
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainingTitleList: []
    }
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    },
    userTypeList () {
      const userList = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Trainer' : 'প্রশিক্ষক', text_en: 'Trainer', text_bn: 'প্রশিক্ষক' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Trainee' : 'প্রশিক্ষণার্থী', text_en: 'Trainee', text_bn: 'প্রশিক্ষণার্থী' }
      ]
      return userList
    },
    roomTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.roomTypeList.filter(item => item.status === 1)
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    trainingTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    }
  },
  methods: {
    details (data, date) {
        this.item = data
        this.date = date
    },
    async getCircularMemoNo (circularMemoNo) {
      if (circularMemoNo) {
          const circularMemoNo = {
            circular_memo_no: this.$route.query.circilarMemoNo,
            other_trainee_type_id: this.$route.query.otherTraineeType,
            payment_type: this.$route.query.payment_type
          }
        this.load = true
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, billPaymentHonorarumDetails, circularMemoNo)
        if (!result.success) {
          this.honorariumSheet = []
        } else {
            this.honorariumSheet = result.data[0]
            this.getCustomDataZeroList(result.data[0])
            const trainees = result.data.filter(trainee => trainee.payment_type === 'Trainer')
            this.trainees = this.getCustomDataList(trainees)
            this.materials = result.matherial
            this.training = result.training
            this.dateList = result.dateList
        }
        this.load = false
      }
    },
    // pdfExport () {
    //   const reportTitle = this.$t('elearning_tim.other_honorarium_amount') + ' ' + this.$t('globalTrans.details')
    //   ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.honorariumSheet, this, this.trainees)
    // },
    pdfExportSheet () {
      this.$refs.details.pdfExportDetails()
    },
    getCustomDataList (data) {
        const listData = data.map(item => {
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org = ''
            orgData.org_bn = ''
          }

          const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          const officeData = {}
          if (typeof officeObj !== 'undefined') {
            officeData.office = officeObj.text_en
            officeData.office_bn = officeObj.text_bn
          } else {
            officeData.office = ''
            officeData.office_bn = ''
          }

          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
            // fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          const trainingTitleData = {}
          if (typeof trainingTitleObj !== 'undefined') {
            trainingTitleData.training_title = trainingTitleObj.text_en
            trainingTitleData.training_title_bn = trainingTitleObj.text_bn
          } else {
            trainingTitleData.training_title = ''
            trainingTitleData.training_title_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
          const OfficeTypeData = {}
          if (typeof OfficeTypeListObj !== 'undefined') {
            OfficeTypeData.office_type = OfficeTypeListObj.text_en
            OfficeTypeData.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            OfficeTypeData.office_type = ''
            OfficeTypeData.office_type_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          const trainingTypeData = {}
          if (typeof trainingTypeObj !== 'undefined') {
            trainingTypeData.training_type = trainingTypeObj.text_en
            trainingTypeData.training_type_bn = trainingTypeObj.text_bn
          } else {
            trainingTypeData.training_type = ''
            trainingTypeData.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          const trainingCategoryData = {}
          if (typeof trainingCategoryObj !== 'undefined') {
            trainingCategoryData.training_category = trainingCategoryObj.text_en
            trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
          } else {
            trainingCategoryData.training_category = ''
            trainingCategoryData.training_category_bn = ''
          }
          const designationObj = this.$store.state.commonObj.designationList.find(doc => doc.value === parseInt(item.designation_id))
          const designationData = {}
          if (typeof designationObj !== 'undefined') {
            designationData.designation_name = designationObj.text_en
            designationData.designation_name_bn = designationObj.text_bn
          } else {
            designationData.designation_name = ''
            designationData.designation_name_bn = ''
          }
          const customItem = {}
          const userTypeObj = this.userTypeList.find(userType => userType.value === item.personal.registration_for)
          if (typeof userTypeObj !== 'undefined') {
            customItem.user_type_name = userTypeObj.text_en
            customItem.user_type_name_bn = userTypeObj.text_bn
          } else {
            customItem.user_type_name = ''
            customItem.user_type_name_bn = ''
          }
          const desigObj = this.$store.state.commonObj.designationList.find(designation => designation.value === parseInt(item.designation_id))
          const desigData = {}
          if (typeof desigObj !== 'undefined') {
            desigData.designation_name = desigObj.text_en
            desigData.designation_name_bn = desigObj.text_bn
          } else {
            desigData.designation_name = ''
            desigData.designation_name_bn = ''
          }
          return Object.assign({}, item, orgData, officeData, fiscalYearData, trainingTitleData, OfficeTypeData, trainingTypeData, trainingCategoryData, designationData, customItem, desigData)
        })
        return listData
      },
    getCustomDataZeroList (item) {
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
          if (typeof orgObj !== 'undefined') {
            this.honorariumSheet.org = orgObj.text_en
            this.honorariumSheet.org_bn = orgObj.text_bn
          } else {
            this.honorariumSheet.org = ''
            this.honorariumSheet.org_bn = ''
          }

          const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          if (typeof officeObj !== 'undefined') {
            this.honorariumSheet.office = officeObj.text_en
            this.honorariumSheet.office_bn = officeObj.text_bn
          } else {
            this.honorariumSheet.office = ''
            this.honorariumSheet.office_bn = ''
          }

          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          if (typeof fiscalYearObj !== 'undefined') {
            this.honorariumSheet.fiscal_year = fiscalYearObj.text_en
            this.honorariumSheet.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            this.honorariumSheet.fiscal_year = ''
            this.honorariumSheet.fiscal_year_bn = ''
          }
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          if (typeof trainingTitleObj !== 'undefined') {
            this.honorariumSheet.training_title = trainingTitleObj.text_en
            this.honorariumSheet.training_title_bn = trainingTitleObj.text_bn
          } else {
            this.honorariumSheet.training_title = ''
            this.honorariumSheet.training_title_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
          if (typeof OfficeTypeListObj !== 'undefined') {
            this.honorariumSheet.office_type = OfficeTypeListObj.text_en
            this.honorariumSheet.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            this.honorariumSheet.office_type = ''
            this.honorariumSheet.office_type_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          if (typeof trainingTypeObj !== 'undefined') {
            this.honorariumSheet.training_type = trainingTypeObj.text_en
            this.honorariumSheet.training_type_bn = trainingTypeObj.text_bn
          } else {
            this.honorariumSheet.training_type = ''
            this.honorariumSheet.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          if (typeof trainingCategoryObj !== 'undefined') {
            this.honorariumSheet.training_category = trainingCategoryObj.text_en
            this.honorariumSheet.training_category_bn = trainingCategoryObj.text_bn
          } else {
            this.honorariumSheet.training_category = ''
            this.honorariumSheet.training_category_bn = ''
          }
      }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
